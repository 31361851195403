.slick-slide img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.custom-dots {
    bottom: 10px;
}

.custom-dots li button:before {
    font-size: 10px;
    color: #fff;
    opacity: 0.5;
}

.custom-dots li.slick-active button:before {
    opacity: 1 !important;
}

.custom-arrow {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    background: transparent !important;
    border: none !important;
    outline: none !important;
    cursor: pointer !important;
}

.custom-prev-arrow {
    left: 10px !important;
    z-index: 2 !important;
}

.custom-next-arrow {
    right: 10px !important;
    z-index: 2 !important;
}