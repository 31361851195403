    a.style-button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        margin: 10px 0 !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
    }

    a.style-button.primary {
        font-family: Antonio-Regular, sans-serif;
        letter-spacing: .03125rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1;
        padding: 15px 25px 14px;
        -webkit-transition: border-color .15s;
        -moz-transition: border-color .15s;
        transition: border-color .15s;
        position: relative;
        background-color: #fff;
        color: #e20101;
        border: 1px solid #ebeff7;
    }

    a.style-button.primary:hover {
        outline: none;
        text-decoration: none;
        border-color: #e20101;
    }

    a.style-button.primary:not(.loader) span {
        display: -moz-box;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        position: relative;
        line-height: 1.1;
        -webkit-transition: -webkit-transform .2s;
        transition: -webkit-transform .2s;
        -moz-transition: transform .2s, -moz-transform .2s;
        transition: transform .2s;
        transition: transform .2s, -webkit-transform .2s, -moz-transform .2s;
    }

    a.style-button.primary:not(.loader) span::after,
    a.style-button.primary:not(.loader) span::before {
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MyAyMiI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTIwMTAxIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0wIDExaDYxLjVNNTEuMi43MUw2MS41OCAxMSA1MS4yIDIxLjI5Ii8+PC9zdmc+);
        display: block;
        width: 2.625rem;
        -webkit-transition: opacity .1s;
        -moz-transition: opacity .1s;
        transition: opacity .1s;
    }

    a.style-button.primary:not(.loader) span::before,
    button.primary:not(.loader) span::before,
    input[type="submit"].primary:not(.loader) span::before {
        -webkit-transform: translateX(-100%) translateX(-25px);
        -moz-transform: translateX(-100%) translateX(-25px);
        transform: translateX(-100%) translateX(-25px);
        position: absolute;
        opacity: 0;
    }

    a.style-button.primary:not(.loader) span::after {
        margin-left: 1.25rem;
        opacity: 1;
    }

    a.style-button.primary:not(.loader):hover span {
        -webkit-transform: translateX(calc(25px + 42px));
        -moz-transform: translateX(-moz-calc(25px + 42px));
        transform: translateX(calc(25px + 42px));
    }

    a.style-button.primary:not(.loader):focus span::before,
    a.style-button.primary:not(.loader):hover span::before,
    button.primary:not(.loader):focus span::before,
    button.primary:not(.loader):hover span::before,
    input[type="submit"].primary:not(.loader):focus span::before,
    input[type="submit"].primary:not(.loader):hover span::before {
        opacity: 1;
    }

    a.style-button.primary:not(.loader):focus span::after,
    a.style-button.primary:not(.loader):hover span::after,
    button.primary:not(.loader):focus span::after,
    button.primary:not(.loader):hover span::after {
        opacity: 0;
    }