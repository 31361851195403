.image-gallery-thumbnails {
    max-width: 90vw !important;
    overflow-x: auto !important;
    scrollbar-width: thin !important;
    scrollbar-color: #04246a #f5f5f5;
}

.image-gallery-thumbnails::-webkit-scrollbar {
    width: 2px !important;
    height: 8px !important;
}

.image-gallery-thumbnails::-webkit-scrollbar-thumb {
    background-color: #04246a;
    border-radius: 4px;
}

.image-gallery-thumbnails::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 4px;
}

.image-gallery-left-nav {
    left: -6% !important;
}

.image-gallery-right-nav {
    right: -6% !important;
}